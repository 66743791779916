import React, { useEffect, Fragment } from "react"
import { scrollToElement } from "../../../utility/utils"
const PrivacyUganda = () => {
  useEffect(() => {
    scrollToElement()
    window.addEventListener("scroll", function () {
      scrollToElement()
    })
  }, [])
  return (
    <Fragment>
      <div className="kuda-inner--heading mt-0 kuda-disclaimer--section">
        <div className="kuda-section--inner">
          {/* <div className="kuda-section--100 inner-spotlight--heading pb-0 text-center">
            <h6 className="text-xl text-xlbold color-black title-bottom--spacing">
              PRIVACY POLICY
            </h6>
          </div> */}

          <div className="kuda-section--100">
            <h6 className="text-left text-xlbold color-black title-bottom--spacing">
              Background
            </h6>
            <div className="text-left kuda-disclaimer--wrap">
              <p className="color-black title-bottom--spacing">
                Kuda Financial Services Limited (“Kuda Uganda” “we” “our” “us”)
                is a subsidiary of Kuda Technologies Limited, a company based in
                London, United Kingdom. This policy describes what information
                we collect about you, how we collect and use the information you
                share with us, and with whom we share that information. You
                don’t have to share any information with us, but to use our
                services, we’ll need some information from you. This policy also
                contains information about when we share your personal
                information with third parties (such as our service providers
                and credit bureaus).
              </p>
            </div>
          </div>

          <div className="kuda-section--100">
            <h6 className="text-left text-xlbold color-black title-bottom--spacing">
              What information do we collect?
            </h6>
            <h6 className="color-black text-semi-bold mb-3">
              Information you give us on Kuda
            </h6>
            <div className="text-left kuda-disclaimer--wrap">
              <p className="color-black title-bottom--spacing">
                We collect and use any information you supply when you interact
                with any of our touch points. When you open an account with us,
                you share details like your name, ID, address and a picture. By
                using our card or the app to transact, you also share details of
                your transactions with us. Additionally, we may request explicit
                permission to see other information like your address book,
                location, photos and data from your camera.
              </p>
              <p className="color-black title-bottom--spacing">
                <ul className="diclaimer-list">
                  <li>
                    Details you give when you sign up for a Kuda Uganda account,
                    like your name, date of birth, gender, phone number,
                    residential address, and email address.
                  </li>
                  <li>Your profile picture.</li>
                  <li>
                    Information you give us through the in-app chat so we can
                    help you.
                  </li>
                </ul>
              </p>

              <h6 className="color-black text-semi-bold mb-3">
                Information you give us when you contact us
              </h6>
              <div className="text-left kuda-disclaimer--wrap">
                <p className="color-black title-bottom--spacing">
                  If you contact via other means than the in-app chat, we
                  collect the following information so we can answer your
                  questions or take action.
                </p>
                <p className="color-black title-bottom--spacing">
                  <ul className="diclaimer-list">
                    <li>
                      The phone number you’re calling from and information you
                      give us during the call
                    </li>
                    <li>
                      The email address you use and the contents of your email
                      (and any attachments).
                    </li>
                    <li>
                      Public details from your social media profile (like
                      Facebook, Instagram or Twitter) if you reach out to us via
                      these platforms, and the contents of your messages or
                      posts to us.
                    </li>
                  </ul>
                </p>
              </div>

              <h6 className="color-black text-semi-bold mb-3">
                Information we collect when you use Kuda
              </h6>
              <div className="text-left kuda-disclaimer--wrap">
                <p className="color-black title-bottom--spacing">
                  <ul className="diclaimer-list">
                    <li>The mobile network and operating system you use, </li>
                    <li>Your IP address and device ID</li>
                    <li>
                      Your phone contacts so you can make airtime purchases or
                      pay contacts on Kuda
                    </li>
                  </ul>
                </p>
              </div>

              <h6 className="color-black text-semi-bold mb-3">
                Information we get from third parties
              </h6>
              <div className="text-left kuda-disclaimer--wrap">
                <p className="color-black title-bottom--spacing">
                  As part of our KYC (Know Your Customer) process, we run checks
                  on the ID information you supply during signup. Also, we run
                  further checks with the credit bureaus to check if we can
                  offer you an overdraft.
                </p>
              </div>
            </div>

            <div className="kuda-section--100">
              <h6 className="text-left text-xlbold color-black title-bottom--spacing">
                How do we use your information?
              </h6>
              <div className="text-left kuda-disclaimer--wrap">
                <p className="color-black title-bottom--spacing">
                  The Ugandan Data Protection Regulation says we need to have a
                  lawful basis for using your personal information. At least one
                  of the following must apply: contractual or legal duty,
                  legitimate interest, public interest, vital individual
                  interest or consent.
                </p>
              </div>
              <h6 className="color-black text-semi-bold mb-3">
                Contractual Obligation
              </h6>
              <div className="text-left kuda-disclaimer--wrap">
                <p className="color-black title-bottom--spacing">
                  We collect certain data from you to fulfil the contract we
                  have with you, or to enter into a contract with you. We use
                  this data to:
                </p>
                <p className="color-black title-bottom--spacing">
                  <ul className="diclaimer-list">
                    <li>
                      Give you the services we agreed to in line with our terms
                      and conditions.
                    </li>
                    <li>
                      Send you messages about your account and other services
                      you use if you get in touch, or we need to tell you about
                      something.
                    </li>
                    <li>
                      Exercise our rights under contracts we’ve entered into
                      with you, like managing, collecting and recovering money
                      you owe us.
                    </li>
                    <li>
                      Investigate and resolve complaints and other issues.
                    </li>
                  </ul>
                </p>
              </div>
              <h6 className="color-black text-semi-bold mb-3">Legal Duty</h6>
              <div className="text-left kuda-disclaimer--wrap">
                <p className="color-black title-bottom--spacing">
                  We have to ensure we aren’t breaking any laws by banking you
                  by preventing illegal activities like money laundering and
                  fraud. To do this, we need your data to
                </p>
                <p className="color-black title-bottom--spacing">
                  <ul className="diclaimer-list">
                    <li>
                      Confirm your identity when you sign up or get in touch.
                    </li>
                    <li>
                      Prevent illegal activities like money laundering, tax
                      evasion and fraud.
                    </li>
                    <li>
                      Keep records of information we hold about you in line with
                      legal requirements.
                    </li>
                    <li>
                      Adhere to banking laws and regulations (these mean we
                      sometimes need to share customer details with regulators,
                      tax authorities, law enforcement or other third parties).
                    </li>
                  </ul>
                </p>
              </div>
              <h6 className="color-black text-semi-bold mb-3">Legal Duty</h6>
              <div className="text-left kuda-disclaimer--wrap">
                <p className="color-black title-bottom--spacing">
                  We have to ensure we aren’t breaking any laws by banking you
                  by preventing illegal activities like money laundering and
                  fraud. To do this, we need your data to
                </p>
                <p className="color-black title-bottom--spacing">
                  <ul className="diclaimer-list">
                    <li>
                      Confirm your identity when you sign up or get in touch.
                    </li>
                    <li>
                      Prevent illegal activities like money laundering, tax
                      evasion and fraud.
                    </li>
                    <li>
                      Keep records of information we hold about you in line with
                      legal requirements.
                    </li>
                    <li>
                      Adhere to banking laws and regulations (these mean we
                      sometimes need to share customer details with regulators,
                      tax authorities, law enforcement or other third parties).
                    </li>
                  </ul>
                </p>
              </div>

              <h6 className="color-black text-semi-bold mb-3">
                Legitimate Interest
              </h6>
              <div className="text-left kuda-disclaimer--wrap">
                <p className="color-black title-bottom--spacing">
                  In some instances, we need to use the data you supply us for
                  our legitimate interests. This means we’re using your data in
                  a way that you might expect us to, for a reason which is in
                  your interest and doesn't override your privacy.
                </p>
              </div>

              <h6 className="color-black text-semi-bold mb-3">Data Security</h6>
              <div className="text-left kuda-disclaimer--wrap">
                <p className="color-black title-bottom--spacing">
                  Kuda implements and maintains appropriate safeguards to
                  protect personal data, taking into account in particular the
                  risks to you, presented by unauthorised or unlawful processing
                  or accidental loss, destruction of, or damage to their
                  personal data.
                </p>
                <p className="color-black title-bottom--spacing">
                  Safeguarding will include the use of encryption and
                  pseudonymisation where appropriate. It also includes
                  protecting confidentiality (i.e. that only those who need to
                  know and are authorised to use personal data have access to
                  it), integrity and availability of the personal data. We
                  regularly evaluate and test the effectiveness of those
                  safeguards to ensure security of our processing of personal
                  data.
                </p>
                <p className="color-black title-bottom--spacing">
                  You should be aware, however, that no method of transmission
                  over the Internet or method of electronic storage is
                  completely secure. While we strive to protect the security of
                  your information and are constantly reviewing and enhancing
                  our information security measures, we cannot guarantee
                  absolute security. Kuda, therefore, accepts no liability for
                  any damage or loss, however caused, in connection with
                  transmission over the internet or electronic storage.
                </p>
                <p className="color-black title-bottom--spacing">
                  Kuda Uganda will appoint or designate a Data protection
                  officer as required by the Data protection act.
                </p>
              </div>

              <h6 className="color-black text-semi-bold mb-3">
                Consent and Access Rights
              </h6>
              <div className="text-left kuda-disclaimer--wrap">
                <p className="color-black title-bottom--spacing">
                  For certain information, we’ll ask for your consent. We’ll ask
                  for your consent to:
                </p>
                <p className="color-black title-bottom--spacing">
                  <ul className="diclaimer-list">
                    <li>
                      Market and communicate our products and services. You can
                      always unsubscribe from receiving these if you want to
                    </li>
                    <li>
                      Help protect you against fraud by tracking the location of
                      your phone if you’ve authorised it
                    </li>
                    <li>View your contact list for airtime purchases.</li>
                  </ul>
                </p>
                <p className="color-black title-bottom--spacing">
                  In the event of any merger, acquisition or other arrangement
                  whereby Kuda sells or transfers all, or a portion of its
                  business or assets (including in the event of a
                  reorganisation, dissolution or liquidation) to third parties,
                  you hereby consent that your personal data held with Kuda can
                  be transferred or assigned to third parties who may become the
                  controllers and/or processors of your personal data that was
                  held by Kuda prior to such merger, acquisition or other
                  arrangement. Kuda shall at all times ensure that you are
                  notified when your personal data is intended to be transferred
                  to third parties in the circumstances outlined in this clause.
                </p>
                <p className="color-black title-bottom--spacing">
                  In the event of any merger, acquisition or other arrangement
                  whereby Kuda sells or transfers all, or a portion of its
                  business or assets (including in the event of a
                  reorganisation, dissolution or liquidation) to third parties,
                  you hereby consent that your personal data held with Kuda can
                  be transferred or assigned to third parties who may become the
                  controllers and/or processors of your personal data that was
                  held by Kuda prior to such merger, acquisition or other
                  arrangement. Kuda shall at all times ensure that you are
                  notified when your personal data is intended to be transferred
                  to third parties in the circumstances outlined in this clause.
                </p>
                <p className="color-black title-bottom--spacing">
                  No consent shall be sought, given or accepted in any
                  circumstance that may engender direct or indirect propagation
                  of atrocities, hate, child rights violation, criminal acts and
                  anti-social conducts.
                </p>
              </div>
            </div>

            <div className="kuda-section--100">
              <h6 className="text-left text-xlbold color-black title-bottom--spacing">
                Who do we share your information with?
              </h6>
              <div className="text-left kuda-disclaimer--wrap">
                <p className="color-black title-bottom--spacing">
                  We will only share personal information with other companies,
                  entities or individuals in the following limited
                  circumstances:
                  <p className="color-black title-bottom--spacing">
                    <ul className="diclaimer-list">
                      <li>We have your consent.</li>
                      <li>
                        We provide such information to other professional
                        advisers or other trusted businesses or persons for the
                        purpose of processing personal information on our
                        behalf. We require that these parties agree to process
                        such information based on our instructions and in
                        compliance with this Privacy Policy and any other
                        appropriate confidentiality and security measures.
                      </li>
                      <li>
                        We have a good faith belief that access, use,
                        preservation or disclosure of such information is
                        reasonably necessary to (i) satisfy any applicable law,
                        regulation, legal process or enforceable governmental
                        request, (ii) enforce applicable terms of service,
                        including investigation of potential violations thereof,
                        (iii) detect, prevent, or otherwise address fraud,
                        security or technical issues, or (iv) protect against
                        imminent harm to the rights, property or safety of Kuda,
                        its users or the public as required or permitted by law.
                      </li>
                      <li>
                        In some instances, we will share the information
                        provided with some third parties that we do business
                        with, like card producers and credit agencies, or with
                        law enforcement agencies when required.
                      </li>
                    </ul>
                  </p>
                </p>
                <p className="color-black title-bottom--spacing">
                  Kuda Uganda is at all times responsible for the security and
                  appropriate use of that data as long as it remains with us.
                </p>
              </div>
            </div>

            <div className="kuda-section--100">
              <h6 className="text-left text-xlbold color-black title-bottom--spacing">
                Links to third party sites
              </h6>
              <div className="text-left kuda-disclaimer--wrap">
                <p className="color-black title-bottom--spacing">
                  Our website or banking application may contain links to other
                  websites owned and operated by third parties. These links are
                  provided for your information and convenience only and are not
                  an endorsement by us of the content of such linked websites or
                  third parties. The information that we collect from you will
                  become available to these websites if you click the link to
                  the websites. These linked websites are neither under our
                  control nor our responsibility. We, therefore, make no
                  warranties or representations, express or implied about the
                  safety of such linked websites, the third parties they are
                  owned and operated by and the suitability or quality of
                  information contained on them. This Privacy Policy does not
                  apply to these websites, thus, if you decide to access these
                  linked third-party websites and/or make use of the information
                  contained on them, you do so entirely at your own risk. We
                  accept no liability for any damage or loss, however caused, in
                  connection with accessing, the use of or reliance on any
                  information, material, products or services contained on or
                  accessed through any such linked websites. We advise that you
                  contact those websites directly for information on their
                  privacy policy, security, data collection and distribution
                  policies.
                </p>
              </div>
            </div>

            <div className="kuda-section--100">
              <h6 className="text-left text-xlbold color-black title-bottom--spacing">
                How long do we keep your data?
              </h6>
              <div className="text-left kuda-disclaimer--wrap">
                <p className="color-black title-bottom--spacing">
                  We keep your information as long as you are a Kuda Uganda
                  customer and for 10 years after that to comply with the law.
                  If required by law, we may keep it for longer.
                </p>
                <p className="color-black title-bottom--spacing">
                  These retention requirements supersede any right to erasure
                  requests under applicable data protection laws. We cannot
                  therefore erase your data in relation to your Kuda Account
                  until after this time period, however we can assure you that
                  your data will be held safely and securely whilst under our
                  supervision.
                </p>
              </div>
            </div>

            <div className="kuda-section--100">
              <h6 className="text-left text-xlbold color-black title-bottom--spacing">
                When do we delete your data?
              </h6>
              <div className="text-left kuda-disclaimer--wrap">
                <p className="color-black title-bottom--spacing">
                  We are basically storing and processing your personal data
                  only as long as it is necessary to perform our obligations
                  under the agreement with you or as long as the law requires us
                  to store it.
                </p>
                <p className="color-black title-bottom--spacing">
                  That means, if the data is not required anymore for statutory
                  or contractual obligations, your data will be deleted.
                </p>
              </div>
            </div>

            <div className="kuda-section--100">
              <h6 className="text-left text-xlbold color-black title-bottom--spacing">
                What are your rights?
              </h6>
              <div className="text-left kuda-disclaimer--wrap">
                <p className="color-black title-bottom--spacing">
                  You have the right to access and request the information that
                  we have for you in our records. You may also ask us to delete
                  our record of you and we will grant this request as long as
                  we’re legally allowed to. You also have the right to object to
                  us using your information for our marketing purposes or any
                  additional services we may be offering you. You reserve the
                  right to request the modification or amendment of your
                  personal data in our possession.
                </p>
                <p className="color-black title-bottom--spacing">
                  You may withdraw your consent, in writing, at any time and may
                  request access to your personal information in our possession
                  by contacting us at{" "}
                  <a className="color-black" href="mailto:dpo@kuda.com">
                    dpo@kuda.com
                  </a>
                  . We can, however, deny you access to the information where we
                  determine that your request is unreasonable.
                </p>
              </div>
            </div>
            <div className="kuda-section--100">
              <h6 className="text-left text-xlbold color-black title-bottom--spacing">
                How to make a complaint or contact us.
              </h6>
              <div className="text-left kuda-disclaimer--wrap">
                <p className="color-black title-bottom--spacing">
                  At Kuda Uganda, we’re extremely committed to respecting and
                  protecting your information. If you have any worries or
                  reservations about your personal information, please contact
                  our Data Protection Officer by:
                </p>
                <p className="color-black title-bottom--spacing">
                  <ul className="diclaimer-list">
                    <li>Sending a message via the app</li>
                    <li>
                      Emailing us at{" "}
                      <a className="color-black" href="mailto:help@kuda.com">
                        help@kuda.com
                      </a>
                    </li>
                    <li>
                      Writing to us at House 42, Plot 54, Kanjokya Street,
                      Kampala. Uganda.
                    </li>
                  </ul>
                </p>
                <p className="color-black title-bottom--spacing">
                  We will do our best to fix the problem.
                </p>
                <p className="color-black title-bottom--spacing">
                  In some instances, we will share the information provided with
                  some third parties that we do business with, like card
                  producers and credit agencies, or with law enforcement
                  agencies when required.
                </p>
              </div>
            </div>

            <div className="kuda-section--100">
              <h6 className="text-left text-xlbold color-black title-bottom--spacing">
                Violation of Privacy
              </h6>
              <div className="text-left kuda-disclaimer--wrap">
                <p className="color-black title-bottom--spacing">
                  We have put in place procedures to deal with any suspected
                  personal data breach and will notify you of any personal data
                  breach and let you know the steps we have taken to remedy the
                  breach and the security measures we have applied to render
                  your personal data unintelligible.
                </p>
                <p className="color-black title-bottom--spacing">
                  All suspected breach of personal data will be remedied within
                  1 (one) month from the date of the report of the breach. If
                  you know or suspect that a personal data breach has occurred,
                  you should immediately contact us help@kuda.com.
                </p>
                <p className="color-black title-bottom--spacing">
                  Kuda Uganda will not be responsible for any personal data
                  breach which occurs as a result of:
                  <p className="color-black title-bottom--spacing">
                    <ul className="diclaimer-list">
                      <li>an event which is beyond our control;</li>
                      <li>an act or threats of terrorism;</li>
                      <li>
                        an act of God (such as, but not limited to fires,
                        explosions, earthquakes, drought, tidal waves and
                        floods) which compromises Kuda’s data protection
                        measures;
                      </li>
                      <li>
                        war, hostilities (whether war be declared or not),
                        invasion, act of foreign enemies, mobilisation,
                        requisition, or embargo;
                      </li>
                      <li>
                        rebellion, revolution, insurrection, or military or
                        usurped power, or civil war which compromises Kuda’s
                        data protection measures;
                      </li>
                      <li>Pandemics or epidemics;</li>
                      <li>
                        the transfer of your personal data to a third party on
                        your instructions; and
                      </li>
                      <li>
                        the use of your personal data by a third party
                        designated by you.
                      </li>
                    </ul>
                  </p>
                </p>
              </div>
            </div>

            <div className="kuda-section--100">
              <h6 className="text-left text-xlbold color-black title-bottom--spacing">
                User Responsibility
              </h6>
              <div className="text-left kuda-disclaimer--wrap">
                <p className="color-black title-bottom--spacing">
                  You are required to familiarise yourself with this policy and
                  to ensure that the information you provide to us is complete,
                  accurate and up to date.
                </p>
              </div>
            </div>

            <div className="kuda-section--100">
              <h6 className="text-left text-xlbold color-black title-bottom--spacing">
                Changes to this document
              </h6>
              <div className="text-left kuda-disclaimer--wrap">
                <p className="color-black title-bottom--spacing">
                  This document will be reviewed on a yearly basis by our Data
                  Protection Officer. If we make any changes, we’ll add a note
                  to this page and if they’re significant changes we’ll let you
                  know by email.
                </p>
                <p className="color-black title-bottom--spacing">
                  This policy was last updated on 5th May 2022.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default PrivacyUganda
